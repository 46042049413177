import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CryptoJsService } from './crypto-js.service';
import { IAuthStaff, IUserProfile } from '../interface/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseUrl = `${environment.apiUrl}${environment.authService}`;
  jwtHelper = new JwtHelperService();
  constructor(
    private http: HttpClient,
    private cryptService: CryptoJsService
  ) {}

  login(email: string, password: string): Observable<IAuthStaff> {
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    return this.http
      .post(
        `${this.baseUrl}/AuthStaff`,
        {
          email: email,
          password: this.cryptService.encryptUsingAES256(password),
        },
        {
          headers: httpHeaders,
        }
      )
      .pipe(
        map((resp: IAuthStaff) => {
          localStorage.removeItem('token');
          localStorage.removeItem('userProfile');
          if (resp.isSuccess) {
            localStorage.setItem('token', resp.accessToken || '');
            localStorage.setItem(
              'userProfile',
              JSON.stringify(resp.userProfile) || ''
            );
          }
          return resp;
        })
      );
  }

  loggedIn() {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token || '');
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('userProfile');
    
  }

  getUserProfile(): IUserProfile {
    return JSON.parse(localStorage.getItem('userProfile') || '');
  }
}

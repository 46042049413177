export const environment = {
  appVersion: 'local V ' + require('../../package.json').version,
  apiUrl: 'https://dev-api.siamese-tfms.com',
  domainApi: 'dev-api.siamese-tfms.com',
  activityService: '/ActivityService',  
  authService: '/AuthService',
  masterService: '/MasterService',
  staffService: '/StaffService',
  orderService: '/BathroomService'
};
